@import "./global.scss";

.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  .login {
    width: 400px;
    background-color: white;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    text-align: center;
    .head {
      padding: 50px 0 20px;
      text-align: left;

      .logo_min {
        margin-left: 50px;
        height: 30px;
        margin-bottom: 20px;
      }
      h1 {
        font-weight: 700;
        margin-left: 50px;
        font-size: 1.2rem;
      }
    }
	.body {
		input {
			padding: 15px;
			margin-bottom: 10px;
			width: 80%;
			border: 1px solid #ccc;
		}
	}
    .corp {
      border-top: 1px dashed black;
      border-bottom: 1px dashed black;
      padding: 20px 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .compte {
        display: flex;
        gap: 10px;
        img {
          padding: 0 10px;
          width: 40px;
        }
        .info {
          .status {
            font-size: 0.7rem;
            line-height: 1rem;
            margin: 0;
          }
        }
      }
    }
    .err {
      color: red;
    }
    .foot {
      padding: 20px 0 40px;
      button {
        margin: 0 auto;
        display: flex;
        gap: 10px;
        align-items: center;
        img {
          height: 40px;
        }
      }
    }
  }
}
