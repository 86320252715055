@import "../../../node_modules/scss-reset/src/scss/_reset.scss";

@font-face {
  font-family: "SanofiSans";
  src: url("../../assets/fonts/Sanofi_Sans-Regular.woff2") format("woff2"),
    url("../../assets/fonts/SanofiSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SanofiSans";
  src: url("../../assets/fonts/Sanofi_Sans-Light.woff2") format("woff2"),
    url("../../assets/fonts/SanofiSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "SanofiSans";
  src: url("../../assets/fonts/Sanofi_Sans-Bold.woff2") format("woff2"),
    url("../../assets/fonts/SanofiSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "SanofiSerif-serif";
  src: url("../../assets/fonts/Sanofi_Serif-Italic.woff2") format("woff2"),
    url("../../assets/fonts/SanofiSerif-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

$smallscreen1: 1300px;
$smallscreen2: 760px;

$font: "SanofiSans", "Verdana", "Geneva", "Tahoma", sans-serif;
$serif: "SanofiSerif-serif", serif;

$primary: #22004c;
$secondary: #7a00e6;
$gris: #f4f2f6;

$size: 14px;
$line-height: 22px;

body {
  font-family: $font;
  font-size: $size;
  line-height: $line-height;
  font-weight: 400;
  color: $primary;
  background-color: $gris;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  overflow-x: hidden;
  max-width: 100%;
}

.clr {
  clear: both;
}

a {
  text-decoration: none;
  color: inherit;
}

a:focus,
a:visited,
a:link,
a:active {
  text-decoration: none;
  outline: none;
}

input:focus,
input:visited,
input:link,
input:active,
textarea:focus,
textarea:visited,
textarea:link,
textarea:active,
select:focus,
select:visited,
select:link,
select:active {
  text-decoration: none;
  outline: none;
  border: none;
}

#root {
  width: 100%;
  height: 100%;
}

.serif {
  font-family: $serif;
}

.secondary {
  color: $secondary;
}

p {
  font-weight: 300;
}

.btn {
  background-color: $secondary;
  padding: 15px 25px;
  color: white;
  font-size: 1.1rem;
  line-height: 1.1rem;
  margin-top: 30px;
  font-weight: 700;
  font-style: italic;
  transition: all 0.2s ease-in;
  display: flex;
  align-items: center;
  .ico {
    height: 15px;
    margin-left: 20px;
    margin-right: 10px;
    transition: all 0.2s ease-in;
    svg {
      fill: white;
    }
  }
  &:hover {
    background-color: $primary;
    .ico {
      margin-left: 30px;
      margin-right: 0;
    }
  }
}

.btn2 {
  background-color: transparent;
  border: 2px solid $secondary;
  padding: 15px 25px;
  color: $secondary;
  font-size: 1.1rem;
  line-height: 1.1rem;
  display: inline-block;
  justify-content: space-between;
  margin-top: 30px;
  transition: all 0.2s ease-in;
  .ico {
    height: 10px;
    margin-right: 20px;
    margin-left: 10px;
    transition: all 0.2s ease-in;
    svg {
      fill: $secondary;
      transform: scaleX(-1);
    }
  }
  &:hover {
    background-color: $secondary;
    color: white;
    .ico {
      margin-right: 30px;
      margin-left: 0;
      svg {
        fill: white;
        transition: all 0.2s ease-in;
      }
    }
  }
}

.btn-small {
  padding: 10px 20px;
  font-size: 1rem;
  line-height: 1.2rem;
  margin-top: 0;
  .ico {
    height: 12px;
    margin-left: 10px;
    margin-right: 5px;
    svg {
      height: 12px;
    }
  }
  &:hover {
    .ico {
      margin-left: 15px;
      margin-right: 0;
    }
  }
}

.center {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.header {
  background-color: white;
  display: flex;
  align-items: center;
  box-shadow: 0 3px 15px $gris;
  padding: 20px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  .content_left {
    width: 20%;
    text-align: center;
    img {
      height: 40px;
    }
  }
  .content_right {
    width: 80%;
    display: flex;
    justify-content: space-between;
    text-align: center;
    padding: 0 30px;
    .big_title {
      font-size: 1.4rem;
      display: flex;
      align-items: center;
    }
    .user_menu {
      position: relative;
      .user {
        color: #665e6f;
        display: flex;
        gap: 20px;
        align-items: center;
        font-size: 1rem;
        line-height: 1.4rem;
        .sep {
          height: 20px;
          border-left: 1px solid $secondary;
        }
        .ico {
          img {
            height: 40px;
          }
        }
        .arrow_menu {
          img {
            height: 10px;
          }
        }
      }
      .submenu {
        position: absolute;
        width: 100%;
        border: 1px solid rgba($color: $primary, $alpha: 0.2);
        background-color: white;
        padding: 10px 0;
        margin-top: 20px;
        display: none;
      }
      .disp {
        display: block;
        transition: all 0.2s ease-out;
      }
    }
  }
}

.footer {
  background-color: white;
  padding: 0 30px;
  position: absolute;
  bottom: 0;
  width: 100%;
  .content {
    padding: 10px 0;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    p {
      font-weight: 300;
    }
    img {
      height: 20px;
    }
  }
}
.mt-4 {
  margin-top: 4rem;
}
.me-2 {
  margin-right: 2rem;
}
.float-left {
  float: left;
}
.cursor-pointer {
  cursor: pointer;
}
.show-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  top: 0;
  width: 100%;
  left: 0;
  background: #ffffffe6;
  border-radius: 60px;
}
.hide-loader, .d-none {
  display: none;
}

.bars-2 {
  width: 50px;
  height: 50px;
  --p: linear-gradient(#22004c 0 0);
  --s: linear-gradient(#7a00e6 0 0);
  background: var(--p) 0% 100%, var(--s) 50% 100%, var(--p) 100% 100%;
  background-size: 10.1px 65%;
  background-repeat: no-repeat;
  animation: bars-1hcj36 1s infinite linear;
 
}
.bars {
  width: 50px;
  //height: 50px;
  --p: linear-gradient(#22004c 0 0);
  --s: linear-gradient(#7a00e6 0 0);
  background: var(--p) 0% 100%, var(--s) 50% 100%, var(--p) 100% 100%;
  background-size: 10.1px 65%;
  background-repeat: no-repeat;
  animation: bars-1hcj36 1s infinite linear;
  span {
    opacity: 0;
  }
}

@keyframes bars-1hcj36 {
  20% {
    background-position: 0% 50%, 50% 100%, 100% 100%;
  }

  40% {
    background-position: 0% 0%, 50% 50%, 100% 100%;
  }

  60% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }

  80% {
    background-position: 0% 100%, 50% 100%, 100% 0%;
  }
}

@media screen and (max-width: $smallscreen1) and (min-width: $smallscreen2) {
  
  .header {
    padding: 15px 0;
    .content_left {
      img {
        height: 30px;
      }
    }
    .content_right {
      padding: 0 20px;
      .big_title {
        font-size: 1.1rem;
      }
      .user_menu {
        position: relative;
        .user {
          gap: 10px;
          font-size: .9rem;
          line-height: 21px;
          .ico {
            img {
              height: 30px;
            }
          }
          .arrow_menu {
            img {
              height: 7px;
            }
          }
        }
      }
    }
  }

  .footer {
    padding: 0 20px;
  }

  .center {
    gap: 5px;
  }
}