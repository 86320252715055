@import './global.scss';

.dashboard-page {
    padding-top: 80px;
    min-height: calc(100vh - 122px);
    .content {
        display: flex;
        .corp {
            padding: 30px;
            padding-left: calc(20% + 30px);
            width: 100%;
            margin-bottom: 40px;
            .bloc {
                margin-bottom: 40px;
                .row {
                    display: flex;
                    justify-content: space-between;
                    .title {
                        h2 {
                            font-weight: 700;
                            font-size: 1.5rem;
                            line-height: 2.2rem;
                        }
                        h4 {
                            font-family: $serif;
                            font-size: 1.2rem;
                            line-height: 1.6rem;
                            color: $secondary;
                        }
                    }
                    .filtres {
                        display: flex;
                        gap: 10px;
                        .filtre {
                            position: relative;
                            .filtre-cont {
                                display: flex;
                                align-items: center;
                                background-color: white;
                                padding: 5px 10px;
                                gap: 10px;
                                cursor: pointer;
                                .ico {
                                    height: 25px;
                                    display: flex;
                                    align-items: center;
                                }
                                p {
                                    font-size: .8rem;
                                    line-height: .8rem;
                                    height: 15px;
                                    position: relative;
                                    top: 3px;
                                    width: 80px;
                                }
                            }
                            .subfiltre {
                                position: absolute;
                                z-index: 10;
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                background-color: white;
                                margin-top: 5px;
                                border: 1px solid rgba($color: $primary, $alpha: .2);
                                max-height: 305px;
                                overflow-y: auto;
                                ul {
                                    margin-left: 10px;
                                    li {
                                        padding: 5px 0 0;
                                        cursor: pointer;
                                        position: relative;
                                        span {
                                            padding-left: 15px;
                                        }
                                        svg {
                                            position: absolute;
                                            left: 0;
                                            top: 9px;
                                            height: 10px;
                                            width: 10px;
                                            margin-right: 5px;
                                        }
                                    }
                                }
                                button, .radiobox {
                                    padding: 8px 20px;
                                    width: 100%;
                                    text-align: left;
                                    font-size: .8rem;
                                    line-height: 1rem;
                                    &:hover {
                                        background-color: rgba($color: $secondary, $alpha: .1);
                                    }
                                }
                                .radiobox {
                                    padding: 0 0 0 10px;
                                    display: flex;
                                    align-items: center;
                                    input[type=radio] {
                                        margin-right: 10px;
                                        -webkit-appearance: checkbox; /* Chrome, Safari, Opera */
                                        -moz-appearance: checkbox;    /* Firefox */
                                        -ms-appearance: checkbox;     /* not currently supported */
                                    }
                                    input[type=checkbox] {
                                        margin-right: 10px;
                                        -webkit-appearance: checkbox; /* Chrome, Safari, Opera */
                                        -moz-appearance: checkbox;    /* Firefox */
                                        -ms-appearance: checkbox;     /* not currently supported */
                                    }
                                    input[disabled] {
                                        color: red;
                                        background-color: black;
                                    }
                                    label {
                                        display: block;
                                        width: 100%;
                                        padding: 8px 20px 8px 0;
                                    }
                                }
                                
                            }
                        }
                        .export-btn {
                            background-color: white;
                            border: 1px solid $secondary;
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            padding: 5px 10px;
                            height: 35px;
                            cursor: pointer;
                            position: relative;
                            .ico {
                                height: 25px;
                                display: flex;
                                align-items: center;
                                svg {
                                    fill: #23004c;
                                    transition: all .2s ease-out;
                                }
                            }
                            p {
                                font-size: .8rem;
                                line-height: .8rem;
                                height: 15px;
                                position: relative;
                                top: 3px;
                                min-width: 105px;
                            }
                            &:hover {
                                background-color: $secondary;
                                color: white;
                                transition: all .2s ease-out;
                                .ico svg {
                                    fill: white;
                                }
                            }
                            .op0 {
                                opacity: 0;
                                position: absolute;
                                left: 0;
                                top: 0;
                                bottom: 0;
                                right: 0;
                                width: 100%;
                            }
                        }
                    }
                    .double-line {
                        display: flex;
                        flex-direction: column;
                        .line {
                            display: flex;
                            gap: 10px;
                            justify-content: flex-end;
                        }
                    }
                }
                .chiffres {
                    display: flex;
                    gap: 0;
                    margin: 40px 0 50px;
                    .chiffre {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 25%;
                        background: url(../images/dot-contour.svg) no-repeat center center;
                        background-size: contain;
                        padding: 35px 0;
                        position: relative;
                        .ico svg {
                            height: 40px;
                            margin-bottom: 10px;
                            fill: $secondary;
                        }
                        .serif {
                            font-size: 1rem;
                            line-height: 1.6rem;
                            margin-bottom: 10px;
                            strong {
                                font-weight: 700;
                            }
                        } 
                        h3 {
                            //font-size: 3.8rem;
                            font-size: 2.2rem;
                            line-height: 3.8rem;
                            font-weight: 700;
                        }
                        &::after {
                            content: "";
                            background: url(../images/dot-shadow.svg) no-repeat center center;
                            background-size: contain;
                            transition: all .2s ease-out;
                            width: 100%;
                            position: absolute;
                            bottom: -50px;
                            height: 140%;
                            z-index: -1;
                            opacity: 0;
                        }
                        &:hover {
                            background: url(../images/dot.svg) no-repeat center center;
                            background-size: contain;
                            color: white;
                            transition: all .2s ease-out;
                            &::after {
                                bottom: -80px;
                                opacity: 1;
                            }
                            .ico svg {
                                height: 40px;
                                margin-bottom: 10px;
                                fill: #f4f2f6;
                                transition: all .2s ease-out;
                            }
                        }
                    }
                }
                .charte {
                    width: 100%;
                    background-color: white;
                    border-radius: 60px;
                    margin: 40px 0 0;
                    padding: 50px 80px 110px;
                    height: 500px;
                    position: relative;
                    h3 {
                        font-size: 1.2rem;
                        line-height: 1.6rem;
                        font-weight: 700;
                        margin-bottom: 40px;
                        span {
                            color: $secondary;
                            font-family: $serif;
                        }
                    }
                    .download {
                        position: absolute;
                        top: 50px;
                        right: 100px;
                        z-index: 1;
                        .dl-btn {
                            .dl-cont {
                                display: flex;
                                gap: 15px;
                                align-items: center;
                                background-color: $gris;
                                border-radius: 10px;
                                padding: 8px 15px;
                                .ico {
                                    height: 20px;
                                    svg {
                                        height: 20px;
                                    }
                                }
                                .arrow {
                                    
                                }
                            }
                            .subfiltre-dl {
                                position: absolute;
                                border-radius: 10px;
                                border: 1px solid $gris;
                                background-color: white;
                                width: 190px;
                                overflow: hidden;
                                .dl-sub {
                                    display: flex;
                                    gap: 10px;
                                    align-items: flex-start;
                                    padding: 10px 15px;
                                    cursor: pointer;
                                    h5 {
                                        font-size: .9rem;
                                    }
                                    p {
                                        font-size: .8rem;
                                        font-family: $serif;
                                    }
                                    &:hover {
                                        background-color: rgba($color: $secondary, $alpha: .1);
                                    }
                                }
                            }
                        }
                    }
                }

                .double-charte {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 4%;
                    .charte {
                        width: 48%;
                        height: 520px;
                        canvas {
                            margin-top: -100px;
                        }
                        .download {
                            right: 60px;
                        }
                        .cta {
                            text-align: center;
                            margin-top: -70px;
                            .btn, .btn2 {
                                display: inline-block;
                                margin-top: 5px;
                            }
                        }
                    }
                }
            }
        }
        .sub-reduced {
            padding-left: 108px;
            transition: all .2s ease-out;
        }
    }
}

@media screen and (max-width: $smallscreen1) and (min-width: $smallscreen2) {
    .dashboard-page {
        padding-top: 60px;
        min-height: calc(100vh - 102px);
        .content {
            .corp {
                padding: 20px;
                padding-left: calc(20% + 20px);
                margin-bottom: 40px;
                .bloc {
                    margin-bottom: 40px;
                    .row {
                        .title {
                            width: 45%;
                            h2 {
                                font-size: 1.4rem;
                                line-height: 2.0rem;
                            }
                        }
                        .filtres {
                            flex-wrap: wrap;
                            justify-content: flex-end;
                            gap: 5px;
                            width: 55%;
                            .filtre {
                                position: relative;
                            }
                            .export-btn {
                                gap: 5px;
                                padding: 5px 10px;
                                height: 35px;
                                .ico {
                                    height: 15px;
                                    svg {
                                        height: 15px;
                                    }
                                }
                            }
                        }
                        .double-line {
                            .line {
                                flex-wrap: wrap;
                            }
                        }
                    }
                    .chiffres {
                        flex-wrap: wrap;
                        justify-content: center;
                        gap: 20px;
                        margin: 20px 0 40px;
                        .chiffre {
                            width: 40%;
                            margin-bottom: 10px;
                        }
                    }
                    .charte {
                        padding: 50px 50px 110px;
                        .download {
                            right: 100px;
                            .dl-btn {
                                .subfiltre-dl {
                                    position: absolute;
                                    border-radius: 10px;
                                    border: 1px solid $gris;
                                    background-color: white;
                                    width: 190px;
                                    overflow: hidden;
                                    .dl-sub {
                                        padding: 8px 15px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .sub-reduced {
                padding-left: 88px;
            }
        }
    }
}