@import './global.scss';

.home-page {
    .page {
        position: relative;
    }
    .elem {
        position: absolute;
        transform: translateY(-50%);
        top: -30%;
        right: -10%;
        z-index: -1;
        img {
            height: 450px;
        }
    }
    .head {
        margin: 25px 30px;
        img {
            height: 40px;
            margin-left: 40px;
        }
    }
    .corp {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;
        position: absolute;
        top: 50vh;
        transform: translateY(-50%);
        width: 100%;
        .content {
            width: 50%;
            margin-left: 40px;
            h2 {
                font-size: 4.8rem;
                line-height: 5rem;
                font-weight: 700;
                margin-bottom: 20px;
            }
            h1 {
                font-size: 2.8rem;
                line-height: 3.2rem;
                font-weight: 400;
                margin-bottom: 20px;
            }
            p {
                font-size: 1.2rem;
                line-height: 1.6rem;
            }
        }
        .key_visual {
            padding: 40px 0;
            img {
                width: 440px;
            }
        }
    }
    .op5 {
        background-color: rgba($color: $primary, $alpha: .5);
    }
    .inline-flex {
        display: flex;
        gap: 2rem;
        .btn button {
            color: white;
            font-style: italic;
            font-weight: 700;
        }
        .btn2 {
            color: $secondary;
            &:hover {
                color: white;
            }
        }
    }
}

@media screen and (max-width: $smallscreen1) and (min-width: $smallscreen2) {
    .home-page {
        .corp {
            .content {
                h2 {
                    font-size: 3.8rem;
                    line-height: 4rem;
                }
                h1 {
                    font-size: 1.8rem;
                    line-height: 2.2rem;
                }
                p {
                    font-size: 1rem;
                    line-height: 1.4rem;
                }
            }
            .key_visual {
                img {
                    width: 240px;
                }
            }
        }
    }
}