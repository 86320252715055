.data-table {
    overflow-x: auto;
    position: relative;
    table {
        text-align: left;
        border-collapse: collapse;
        tr {
            th, td {
                padding: 10px 20px;
            }
        }
        thead {
            tr {
                font-size: 1rem;
                line-height: 1.4rem;
                font-weight: 700;
                th {
                    padding: 10px 30px;
                    background-color: white;
                    border-bottom: 5px solid #F4F2F6;
                    &:first-of-type {
                        border-radius: 20px 0 0 0;
                    }
                    &:last-of-type {
                        border-radius: 0 20px 0 0;
                    }
                }
                td {
                    padding: 15px 20px;
                    border-bottom: 5px solid #F4F2F6;
                }
            }
        }
        tbody {
            tr {
                background-color: white;
                font-size: 1rem;
                line-height: 1.4rem;
                font-weight: 700;
                th {
                    border:none;
                    border-right: 2px solid #7A00E6;
                    padding: 15px 30px;
                    min-width: 250px;
                }
                td {
                    border: 1px solid rgba($color: #23004C, $alpha: .2);
                    font-size: 1rem;
                    line-height: 1.4rem;
                    font-weight: 300;
                    padding: 15px 30px;
                    min-width: 110px;
                }
                &:nth-of-type(even) {
                    background-color: #f5f3f7
                }
            }
        }
    }
}
