$smallscreen1: 1300px;
$smallscreen2: 760px;

.slide-menu {
    width: 20%;
    //min-height: calc(100vh - 122px);
    height: 100vh;
    background-color: white;
    position: fixed;
    top: 0;
    padding-top: 80px;
    padding-bottom: 42px;
    z-index: 20;
    transition: all 0.2s ease-out;
    .menu {
        padding: 30px 0;
        ul {
            li {
                a {
                    padding: 15px 20px;
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    font-size: 1.1rem;
                    color: #665E6F;
                    strong {
                        fill: #7A00E6;
                        height: 40px;
                    }
                    &:hover {
                        background-color: rgba($color: #7A00E6, $alpha: .1);
                        transition: all .2s ease-out;
                    }
                    span {
                        fill: #7A00E6;
                    }
                }
                .slide_submenu {
                    margin-left: 38px;
                    li {
                        border-left: 1px solid #7A00E6;
                        padding-left: 0;
                        transition: all .2s ease-in-out;
                        a {
                            font-size: 1rem;
                            padding-left: 35px;
                        }
                    }
                }
            }
            .selected {
                background-color: #7A00E6;
                box-shadow: 0 15px 30px -15px rgba($color: #22004C, $alpha: .3);
                a {
                    color: white;
                    strong {
                        fill: white;
                    }
                }
            }
        }
    }
    .hide_menu {
        position: absolute;
        bottom: 52px;
        padding: 15px 20px;
        display: flex;
        align-items: center;
        gap: 20px;
        font-size: 1.1rem;
        color: #905ace;
        width: 100%;
        cursor: pointer;
        strong {
            fill: #7A00E6;
            height: 40px;
            transition: all .2s ease-out;
        }
        p {
            color: #665E6F;
        }
        &:hover {
            background-color: rgba($color: #7A00E6, $alpha: .1);
        }
    }
}
.menu_min {
    width: 78px;
    .menu ul li {
        a {
            p, span {
                display: none;
            }
        }
        .slide_submenu {
            position: absolute;
            left: 45px;
            margin-top: -70px;
            width: 200px;
            background-color: white;
            border: 1px solid rgba($color: #7A00E6, $alpha: .2);
            li {
                border: none;
                a {
                    padding: 10px 20px;
                }
            }
        }
    }
    .hide_menu {
        strong {
            transform: rotate(-180deg);
        }
        p {
            display: none;
        }
    }
}

@media screen and (max-width: $smallscreen1) and (min-width: $smallscreen2) {
    .slide-menu {
        padding-top: 60px;
        .menu {
            padding: 20px 0;
            ul {
                li {
                    a {
                        padding: 15px 20px;
                        gap: 10px;
                        font-size: 1rem;
                        strong {
                            fill: #7A00E6;
                            height: 30px;
                            width: 30px;
                            svg {
                                height: 30px;
                                width: 30px;
                            }
                        }
                    }
                    .slide_submenu {
                        margin-left: 34px;
                        li {
                            a {
                                font-size: .9rem;
                                padding-left: 25px;
                            }
                        }
                    }
                }
            }
        }
        .hide_menu {
            gap: 10px;
            font-size: 1rem;
            strong {
                height: 30px;
                width: 30px;
                svg {
                    height: 30px;
                    width: 30px;
                }
            }
        }
    }
    .menu_min {
        width: 68px;
        .menu ul li {
            a {
                p, span {
                    display: none;
                }
            }
            .slide_submenu {
                left: 40px;
                margin-top: -60px;
                li {
                    a {
                        padding: 10px 15px;
                    }
                }
            }
        }
    }
}